/**
 * v0 by Vercel.
 * @see https://v0.dev/t/hOc8nxrKoco
 */
//import Link from "next/link"
import { Helmet } from "react-helmet";

import { Icon } from "@iconify/react";
import pirateIcon from "@iconify/icons-mdi/pirate";
import bookIcon from "@iconify/icons-tabler/book";
import diamondIcon from "@iconify/icons-ion/diamond";
import keyLinear from "@iconify/icons-solar/key-linear";
import shopFilled from "@iconify/icons-ant-design/shop-filled";
import mailOutline from "@iconify/icons-ion/mail-outline";
import twitterIcon from "@iconify/icons-pajamas/twitter";
import tiktokIcon from "@iconify/icons-bxl/tiktok";
import telegramIcon from "@iconify/icons-jam/telegram";
import instagramIcon from '@iconify/icons-jam/instagram';

export default function Component() {
  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>bBook - Your Next Favourite Reading Experience</title>
        <meta
          name="description"
          content="Discover bBook, a revolutionary way to read and interact with books. Elevate your reading experience to new heights with bBook."
        />
        <meta
          name="keywords"
          content="bBook, bBooks, eBooks, digital reading, interactive books, reading platform, book club, editors, Personalized Reading, Digital Book Club, Interactive eBook Platform, Customizable eBooks, Digital Publishing, Author Engagement, Reader Community, Sequential Chapter Release, Digital Rights Management, eBook Ownership, Educational Reading Tools, Creative Writing Platform, Exclusive Reader Benefits, Innovative Reading Technology, Digital Bookstore, eBook Distribution, Literary Community, Book Club Management, Interactive Storytelling, Enhanced Reading Experience"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="UTF-8"></meta>
        <meta name="robots" content="index, follow"></meta>
        <meta http-equiv="content-language" content="en-us"></meta>
        <meta
          http-equiv="Content-Type"
          content="text/html; charset=utf-8"
        ></meta>

        {/* Other relevant meta tags */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "book tecnology",
              "name": "bBook - New technology for your digital books",
              "description": "bBooks is an innovative technology offering a unique reading experience. We specialize in personalized books, incorporating user notes, emotions, and illustrations, and provide exclusive benefits. Our technology also allows authors to release book chapters sequentially, enhancing reader engagement.",  
              "url": "https://bbooks.io",
              "sameAs": [
                "https://pluteo.io",
                "https://www.tiktok.com/@pluteo.eth?_t=8imZQEAzUJN&_r=1",
                "https://twitter.com/Pluteoeth"
              ],
            }
          `}
        </script>
      </Helmet>
      <header className="px-4 lg:px-6 h-14 flex items-center">
        <a className="flex items-center justify-center" href="#">
          {/* <BookIcon className="h-6 w-6" /> */}
          <Icon icon={bookIcon} className="h-6 w-6" />
          <span className="sr-only">bBooks</span>
        </a>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#features"
          >
            Features
          </a>
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#usecases"
          >
            Use Cases
          </a>
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#contact"
          >
            Contact
          </a>
        </nav>
      </header>
      <main className="flex-1">
        <section className="w-full pt-12 md:pt-24 lg:pt-32 border-y">
          <div className="px-4 md:px-6 space-y-10 xl:space-y-16">
            <div className="grid max-w-[1300px] mx-auto gap-4 px-4 sm:px-6 md:px-10 md:grid-cols-2 md:gap-16">
              <div>
                <h1 className="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
                  bBook, your next favourite reading experience
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 mt-5">
                  The Next Evolution of eBook! <br></br> bBook elevates your
                  reading experience to new heights.
                  {/* The Next Evolution in Digital Reading! Blending the best of
                  physical books and eBooks, the BBook elevates your reading experience
                  to new heights. */}
                </p>
                <div className="space-x-4">
                  {/* <a
                    className="inline-flex h-9 items-center justify-center rounded-md bg-gray-900 px-4 py-2 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                    href="#"
                  >
                    Get Started
                  </a>
                  <a
                    className="inline-flex h-9 items-center justify-center rounded-md border border-gray-200 border-gray-200 bg-white px-4 py-2 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus-visible:ring-gray-300"
                    href="#"
                  >
                    Learn More
                  </a> */}
                </div>
              </div>
              <div className="flex flex-col items-start space-y-4">
                <img
                  alt="Hero"
                  className="mx-auto aspect-video overflow-hidden rounded-xl object-cover object-center sm:w-full lg:order-last"
                  height="300"
                  src="/simpleBooks.png"
                  width="500"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="w-full py-12 md:py-24 lg:py-32">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <div className="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm dark:bg-gray-800 dark:text-gray-400">
                  Key Features
                </div>
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                  Why Choose bBooks?
                </h2>
                <p className="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  Enjoy the tangible benefits of a physical book coupled with
                  the digital world's conveniences.
                </p>
              </div>
            </div>
            <div className="mx-auto grid items-start gap-8 sm:max-w-4xl sm:grid-cols-2 md:gap-12 lg:max-w-5xl lg:grid-cols-1">
              <div className="grid gap-1">
                {/* <BookOpenIcon className="h-6 w-6 mb-2" /> */}
                <div className="flex gap-2 mx-auto">
                  <Icon icon={bookIcon} className="h-6 w-6" />
                  <h3 className="text-lg font-bold">Uniqueness</h3>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  Each copy of a bBook will have its own story.
                </p>
              </div>
              <div className="grid gap-1">
                {/* <CloudIcon className="h-6 w-6 mb-2" /> */}
                <div className="flex gap-2 mx-auto">
                  <Icon icon={diamondIcon} className="h-6 w-6 mb-2" />
                  <h3 className="text-lg font-bold">Incorruptibility</h3>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  The works of the greatest authors, combined with subsequent
                  interactions, will remain etched in time, forever.
                </p>
              </div>
              <div className="grid gap-1">
                {/* <ShareIcon className="h-6 w-6 mb-2" /> */}
                <div className="flex gap-2 mx-auto">
                  <Icon icon={pirateIcon} className="h-6 w-6 mb-2" />
                  <h3 className="text-lg font-bold">Copyright protection</h3>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  The bBook cannot be illegally distributed online.
                </p>
              </div>
              <div className="grid gap-1">
                {/* <ShareIcon className="h-6 w-6 mb-2" /> */}
                <div className="flex gap-2 mx-auto">
                  <Icon icon={keyLinear} className="h-6 w-6 mb-2" />
                  <h3 className="text-lg font-bold">Ownership</h3>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  Owning a bBook is effectively equivalent to owning a physical
                  book, with all the ensuing benefits.
                </p>
              </div>
              <div className="grid gap-1">
                {/* <ShareIcon className="h-6 w-6 mb-2" /> */}
                <div className="flex gap-2 mx-auto">
                  <Icon icon={shopFilled} className="h-6 w-6 mb-2" />
                  <h3 className="text-lg font-bold">New markets</h3>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  Readers will have the opportunity to sell their bBooks once
                  they have read them. The authors/publishing house will
                  continue to earn royalties on each transaction.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          id="usecases"
          className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800"
        >
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <div className="inline-block rounded-lg bg-white px-3 py-1 text-sm dark:bg-white">
                  Use Cases
                </div>
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl dark:text-gray-200">
                  How bBooks Can Be Used
                </h2>
                <p className="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  bBooks can be used in a variety of ways to enhance your
                  reading experience. Here are a few examples.
                </p>
              </div>
            </div>
            <div className="mx-auto grid items-start gap-8 sm:max-w-4xl sm:grid-cols-2 md:gap-12 lg:max-w-5xl lg:grid-cols-1">
              <details className="grid gap-1">
                <summary className="text-lg font-bold dark:text-gray-200">
                  Book Clubs
                </summary>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  Comment, Share and discuss books with your book club members.
                  The bBook thus offers an innovative tool capable of engaging
                  the reader on a deeper level than mere reading. From being a
                  mere consumer, the user becomes an active participant in the
                  life of the book.
                </p>
              </details>
              <details className="grid gap-1">
                <summary className="text-lg font-bold dark:text-gray-200">
                  Education
                </summary>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  Use bBooks in the classroom to provide students with a wide
                  range of reading materials. Add note to your bBook in order to
                  integrate our material.
                </p>
              </details>
              <details className="grid gap-1">
                <summary className="text-lg font-bold dark:text-gray-200">
                  Marketing tool for creators
                </summary>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  Creators can personalize books by adding their own notes,
                  emotions, and illustrations. They can then offer these unique
                  books to their communities.
                </p>
              </details>
              <details className="grid gap-1">
                <summary className="text-lg font-bold dark:text-gray-200">
                  Releasing Book Chapters Sequentially
                </summary>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  Following in the footsteps of Wattpad and Webtoon, it is
                  possible to create books whose chapters can be published week
                  by week.
                </p>
              </details>
              <details className="grid gap-1">
                <summary className="text-lg font-bold dark:text-gray-200">
                  Offering Exclusive Benefits to Users
                </summary>
                <p className="text-sm text-gray-500 dark:text-gray-400 mx-5">
                  Meet-ups, exclusive copies, or personalized messages can be
                  sent to the actual owner of the book, without fear that they
                  are not the true owner of the copy.
                </p>
              </details>
            </div>
          </div>
        </section>
        <section id="contact" className="w-full py-12 md:py-24 lg:py-32 ">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-3">
              <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight">
                Contact Us
              </h2>
              <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                Have questions or need help? Get in touch with us.
              </p>
            </div>
            <div className="mflex flex-col items-center justify-center space-y-4 text-center">
              <div className="flex items-center gap-3">
                {/* <MailIcon className="h-6 w-6 mb-2" /> */}
                <Icon icon={mailOutline} className="h-6 w-6 mb-2" />
                <div className="grid gap-0.5 text-xs">
                  <div className="font-medium">Email</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    info@pluteo.io
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                {/* <PhoneIcon className="h-6 w-6 mb-2" /> */}
                <Icon icon={twitterIcon} className="h-6 w-6 mb-2" />
                <div className="grid gap-0.5 text-xs">
                  <div className="font-medium">X</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    <a href="https://twitter.com/Pluteoeth">@Pluteoeth</a>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                {/* <PhoneIcon className="h-6 w-6 mb-2" /> */}
                <Icon icon={tiktokIcon} className="h-6 w-6 mb-2" />
                <div className="grid gap-0.5 text-xs">
                  <div className="font-medium">TikTok</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    <a href="https://www.tiktok.com/@pluteo.eth?_t=8imZQEAzUJN&_r=1">
                      @pluteo.eth
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                {/* <PhoneIcon className="h-6 w-6 mb-2" /> */}
                <Icon icon={instagramIcon} className="h-6 w-6 mb-2" />
                <div className="grid gap-0.5 text-xs">
                  <div className="font-medium">Instagram</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    <a href="https://www.instagram.com/pluteo.eth/">
                      pluteo.eth
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                {/* <PhoneIcon className="h-6 w-6 mb-2" /> */}
                <Icon icon={telegramIcon} className="h-6 w-6 mb-2" />
                <div className="grid gap-0.5 text-xs">
                  <div className="font-medium">Telegram</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    <a href="https://t.me/pluteo_eth">t.me/pluteo_eth</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          © bBooks. All rights reserved to Pluteo S.R.L. - VAT IT02109050431
        </p>
        <a href="https://pluteo.io" className="m-auto ">
          <img src="Pluteo_nero.png" className="h-6 w-6" />
        </a>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a className="text-xs hover:underline underline-offset-4" href="https://www.termsfeed.com/live/d19d69ba-4830-49f5-871a-d9cb543c7725">
            Terms of Service
          </a>
          {/* <a className="text-xs hover:underline underline-offset-4" href="#">
            Privacy
          </a> */}
        </nav>
      </footer>
    </div>
  );
}

// function BookIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" />
//     </svg>
//   );
// }

// function BookOpenIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
//       <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
//     </svg>
//   );
// }

// function CloudIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M17.5 19H9a7 7 0 1 1 6.71-9h1.79a4.5 4.5 0 1 1 0 9Z" />
//     </svg>
//   );
// }

// function MailIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <rect width="20" height="16" x="2" y="4" rx="2" />
//       <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
//     </svg>
//   );
// }

// function PhoneIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
//     </svg>
//   );
// }

// function ShareIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
//       <polyline points="16 6 12 2 8 6" />
//       <line x1="12" x2="12" y1="2" y2="15" />
//     </svg>
//   );
// }
